<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop />
    <v-row class="mt-8"></v-row>
    <v-main class="mt-12">
      <v-row class="mt-12">
        <v-col lg="3" md="1" cols="1">
          <!-- Fills extra space in the row -->
        </v-col>
        <v-col cols="10" lg="8" sm="10" class="mt-12 text-justify">
          <p :class="{'display-1': $vuetify.breakpoint.lgAndUp, 'headline': $vuetify.breakpoint.mdAndDown}">Bulk Upload</p>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col lg="3" md="1" cols="1">
          <NavDrawer />
        </v-col>
        <v-col cols="10" lg="8" offset-lg="3" sm="10" class="mt-4 text-justify font-weight-regular">
          <!-- Page content belongs here-->
          <h1>Instructions</h1>
          <p>
            To bulk upload several photos at once, upload a .csv file using the provided csv template.
            Include a zip folder with images with names that correspond to the "jpgName" column in the template.
            Sometimes zip folders will include metadata files that will not correspond to an entry in your .csv file.
            This is normal and not typically an issue, so it is important to read the errors list carefully.
            Upon submission, this webpage will build a preview of your intended upload, and then prompt you
            to commit your upload. Both these steps can take several minutes with large files. Please do not
            leave this page mid-upload. You will receive a popup notifying you the upload is complete.
            You can download a copy of the .csv template <a href="/files/bulk_upload_template.csv" download>here.</a>
          </p>
          <p>CSV Upload</p>
            <v-file-input accept=".csv"
                          id="csvUploadField"
                          v-model="uploadCSVData.file">
            </v-file-input>
            <p>Zip Upload</p>
            <v-file-input accept=".zip, .7z"
                          id="zipUploadField"
                          v-model="uploadZipData.file">
            </v-file-input>
          <v-btn id="preview-button" v-on:click="generate_preview" :disabled="isPreviewDisabled" :loading="loading">Generate Preview</v-btn>
          <br>
          <div id="preview" class="overflow-x-auto"></div>
          <div id ="errors" class="overflow-x-auto"></div>
          <div id="submission-buttons" hidden="hidden">
            <v-btn id="cancel" v-on:click="cancel" :disabled="isCancelDisabled">Cancel</v-btn> <v-btn v-on:click="submit" :loading="loading">Submit</v-btn>
          </div>
          <div style="margin-bottom: 64px" />
        </v-col>
      </v-row>
    </v-main>
    <TheFooter />
  </v-container>
</template>

<script>
import axios from 'axios';
import NavTop from '@/components/NavTop.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import TheFooter from '@/components/TheFooter.vue';

export default {
  name: 'BulkUpload',
  components: {
    NavTop,
    NavDrawer,
    TheFooter,
  },
  data() {
    return {
      title: 'Bulk Upload',
      uploadCSVData: getInitialData(),
      uploadZipData: getInitialData(),
      isCancelDisabled: false,
      loading: false,
    };
  },
  methods: {
    async generate_preview() {
      const that = this;
      that.loading = true;
      const fileObj = new FormData();
      fileObj.append('csvfiles', that.uploadCSVData.file);
      fileObj.append('zipfiles', that.uploadZipData.file);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data; charset=utf-8',
        },
        method: 'post',
      };
      //console.log(fileObj);
      axios.post('/admin/bulk-upload/preview', fileObj, config).then((response) => {
        const returnedDF = response.data[0];
        const returnedErrors = response.data[1];
        buildTable(returnedDF);
        buildErrorTable(returnedErrors);
        that.loading = false;
      }).catch((error) => {
        that.loaderOverlay = false;
        that.loading = false;
        that.errorMsg = error;
        console.error(error);
      });
    },
    submit() {
      const that = this;
      that.loading = true;
      that.isCancelDisabled = true;
      const fileObj = new FormData();
      fileObj.append('csvfiles', that.uploadCSVData.file);
      fileObj.append('zipfiles', that.uploadZipData.file);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data; charset=utf-8',
        },
        method: 'post',
      };
      //console.log(fileObj);
      axios.post('/admin/bulk-upload/submit', fileObj, config).then((response) => {
        // eslint-disable-next-line no-alert
        //console.log(response);
        alert('Images submitted. Refreshing page.');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }).catch((error) => {
        that.loaderOverlay = false;
        that.loading = false;
        that.errorMsg = error;
        console.error(error);
      });
    },
    cancel() {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    },
  },
};

function getInitialData() {
  return {
    file: null,
  };
}

async function buildTable(returnedDF) {
  document.getElementById('preview').innerHTML = returnedDF;
  document.getElementById('submission_buttons').hidden = false;
}

async function buildErrorTable(returnedErrors) {
  if (returnedErrors) {
    document.getElementById('errors').innerHTML = returnedErrors;
  }
}
</script>

<style>
a {
  font-weight: bold;
}

#preview table, #errors table {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 4px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

#preview th, #errors th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 4px;
  text-align: center;
}

#preview img {
  width: 100px;
}

#submission_buttons. #preview-button {
  margin-bottom: 64px;
}

#errors table {
  background-color: #FFCCCB;
}
</style>
